import React, { Component } from 'react';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import PageHelmet from '../component/common/Helmet';
import Footer from '../component/footer/Footer';
import Header from '../component/header/Header';

class ServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Säkerhet" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.svg"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--22"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">SÄKERHET</h2>
                  <p>Från öppet till säkert</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <picture>
                            <source
                              className="w-100"
                              srcSet="/assets/images/service/security.webp"
                              type="image/webp"
                            />
                            <img
                              className="w-100"
                              src="/assets/images/service/security.jpg"
                              alt="Service Images"
                            />
                          </picture>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <p>
                            Många företag lever idag under falska övertygelsen
                            om att deras system är säkra. Egentligen så har
                            många glömt att låsa ytterdörren utan att veta om
                            det.
                          </p>
                          <p>
                            Vi hjälper till att kolla så att systemen verkligen
                            är säkra, och i de flesta fall säkra upp där
                            mänskliga fel inneburit en säkerhetslucka i
                            systemet.
                          </p>
                          <h4 className="title">Process</h4>
                          <ul className="liststyle">
                            <li>
                              Ni bestämmer vilken del i systemet ni vill
                              kontrollera
                            </li>
                            <li>Vi utför penetrationstester</li>
                            <li>
                              Vi använder egenbyggda verktyg för att kontrollera
                              appar / webbsidor
                            </li>
                            <li>
                              Vi återkommer med en rapport på alla fel och hur
                              kritiska dem är
                            </li>
                            <li>Vi går in och löser felen i systemen</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
